<template>
  <v-card class="mx-auto chips-total elevation-x mb-3">
    <v-subheader>Membres</v-subheader>

    <template v-if="Array.isArray(memberships)">
      <v-list class="pb-0" two-line>
        <user-list-item v-for="membership in memberships" :key="membership.user"
                        :user="person(membership.user)"
        />
      </v-list>


      <v-card-actions>
        <v-dialog v-if="total > limit" v-model="membersDialogVisible" max-width="600"
                  content-class="is-scroll-target"
                  :fullscreen="$store.state.layout.isMobile"
                  transition="dialog-bottom-transition"
                  @keydown.esc="membersDialogVisible = false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="actions white--text" class="ma-auto" v-bind="attrs" v-on="on">
              Voir {{ total - memberships.length }} autres
            </v-btn>
          </template>

          <establishment-members :establishment="establishment" @close="membersDialogVisible = false" />
        </v-dialog>
      </v-card-actions>
      <!--                        <router-link class="add-member c-pointer">-->
      <!--                            <img class="elevation-x" src="../../assets/img/profile/add.svg" alt="home"/>-->
      <!--                        </router-link>-->

      <div v-if="memberships.length === 0" class="empty-list container pt-0">
        <div class="body-2 grey--text text--darken-2">Aucun membre</div>
        <div class="caption grey--text">Aucun membre dans cet établissement</div>
      </div>
    </template>

    <skeleton-list v-else small />
  </v-card>
</template>

<script>
  import {Person, Establishment, EstablishmentMembership} from '@/models'
  import UserListItem from '@/modules/core/layout/UserListItem'
  import {mapActions} from 'vuex'
  import SkeletonList from '@/modules/core/layout/SkeletonList'
  import EstablishmentMembers from '@/modules/establishments/components/EstablishmentMembers'

  export default {
    name: 'EstablishmentFirstMembers',
    components: {EstablishmentMembers, SkeletonList, UserListItem},
    props: {
      establishment: Establishment
    },
    data() {
      return {
        membersDialogVisible: false
      }
    },
    computed: {
      total() {
        return this.$store.state.establishments.memberships.total
      },
      limit() {
        return this.$store.state.establishments.memberships.limit
      },
      memberships() {
        return Array.isArray(this.$store.state.establishments.memberships.items[1]) ? this.$store.state.establishments.memberships.items[1].slice(0, 5).map(m => EstablishmentMembership.get(m)) : null
      },
      person() {
        return p => Person.get(p)
      },
      actions() {
        return membership => {
          let actions = []
          if (membership.user !== this.$auth.user.sub && this.$can('update_managers', this.establishment)) {
            let isManager = membership.elevation === 'manager'
            actions.push({
              label: isManager ? 'Retirer des managers' : 'Ajouter aux managers',
              action: async () => {
                await membership.setManager(!isManager)
              }
            })
          }
          return actions
        }
      }
    },
    mounted() {
      this.fetchMembers()
    },
    methods: {
      ...mapActions('establishments', ['fetchMembers'])
    }
  }
</script>

<style scoped>

</style>
