<template>
  <v-card class="mx-auto chips-total elevation-x mb-3 mt-5">
    <v-subheader>Documents</v-subheader>
    <v-list two-line>
      <v-list-item @click.stop="">
        <v-list-item-avatar>
          <v-img src="@/assets/img/files/pdf.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Radio de la vie</v-list-item-title>
          <v-list-item-subtitle>130Ko</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!--                        <router-link class="add-member c-pointer">-->
    <!--                            <img class="elevation-x" src="../../assets/img/profile/add.svg" alt="home"/>-->
    <!--                        </router-link>-->

    <!--                        <div v-if="establishment.documents.length === 0"  class="empty-list container pt-0">-->
    <!--                            <div class="body-2 grey&#45;&#45;text text&#45;&#45;darken-2">Aucun document</div>-->
    <!--                            <div class="caption grey&#45;&#45;text">Aucun document dans cet établissement</div>-->
    <!--                        </div>-->
  </v-card>
</template>

<script>
  import {Establishment} from '@/models'

  export default {
    name: 'EstablishmentFiles',
    props: {
      establishment: Establishment
    }
  }
</script>

<style scoped>

</style>
