<template>
  <v-card class="mx-auto elevation-x mt-md-0 mt-11">
    <div class="actions-bar d-flex justify-center align-center p-abs w-100" />
    <div class="pt-12 pt-md-3 px-3 pb-2">
      <div class="title text-center" v-text="establishment.name" />
      <address-displayer list :address="establishment.address" />
      <v-divider />
      <v-row class="informations-secondary my-2">
        <v-col class="py-1 px-3 ma-0" cols="12" md="12" sm="12" xl="6" lg="6">
          <v-icon class="body-2 primary--text mr-2">far fa-at</v-icon>
          <div v-if="establishment.description"
               class="d-inline-block body-2 font-weight-light"
               v-text="establishment.description"
          />
          <div v-else class="d-inline-block body-2 font-weight-light">
            Non renseigné
          </div>
        </v-col>
        <v-col class="py-1 px-3 ma-0" cols="12" md="12" sm="12" xl="6" lg="6">
          <div v-if="establishment.phone">
            <v-icon class="body-2 primary--text mr-2">far fa-phone</v-icon>
            <div v-if="establishment.phone"
                 class="d-inline-block body-2 font-weight-light"
                 v-text="establishment.phone"
            />
            <br>
          </div>
          <div v-else>
            <v-icon class="body-2 primary--text mr-2">far fa-phone</v-icon>
            <div class="d-inline-block body-2 font-weight-light">Non renseigné</div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  import {Establishment} from '@/models'
  import AddressDisplayer from '@/modules/core/components/AddressDisplayer'

  export default {
    name: 'EstablishmentInfo',
    components: {AddressDisplayer},
    props: {
      establishment: Establishment
    }
  }
</script>

<style scoped>

</style>
