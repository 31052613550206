<template>
  <div id="c-establishment-view" class="profile w-100 p-rel">
    <v-container v-if="establishment && establishment.feedList" fluid>
      <v-row>
        <v-col cols="12" md="6" xl="4" lg="5">
          <establishment-info :establishment="establishment" />
          <establishment-files :establishment="establishment" />
          <establishment-first-members :establishment="establishment" />
          <establishment-actions :establishment="establishment" />
        </v-col>
        <v-col cols="12" md="6" xl="8" lg="7">
          <feed :on="establishment" :feed-list-uid="establishment.feedList" not-on-space allow-alert />
        </v-col>
      </v-row>
    </v-container>
    <skeleton-profil v-else />
  </div>
</template>

<script>

  import {Establishment, Space} from '@/models'
  import EstablishmentInfo from '@/modules/establishments/components/EstablishmentInfo'
  import EstablishmentFiles from '@/modules/establishments/components/EstablishmentFiles'
  import EstablishmentActions from '@/modules/establishments/components/EstablishmentActions'
  import EstablishmentFirstMembers from '@/modules/establishments/components/EstablishmentFirstMembers'
  import SkeletonProfil from '@/modules/core/layout/SkeletonProfil'
  import Feed from '@/modules/feeds/components/Feed'

  export default {
    name: 'Establishment',
    components: {
      Feed,
      SkeletonProfil,
      EstablishmentFirstMembers,
      EstablishmentActions, EstablishmentFiles, EstablishmentInfo
    },
    data() {
      return {
        breadcrumbs: [{text: 'Fiche établissement', disabled: true, to: '/'}]
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      },
      establishment() {
        return this.space && this.space.establishment ? Establishment.get(this.space.establishment) : null
      }
    },
    async mounted() {
      let establishment = await Establishment.fetchOne(this.space.establishment)
      await Promise.all([establishment.fetchFeedList(), establishment.fetchManagers()])
    }
  }
</script>
