<template>
  <v-card id="tour-pro-interactions" class="mx-auto mt-5 elevation-x">
    <v-subheader>Actions possibles</v-subheader>
    <v-card-text class="pt-0">
      <feed-subscribe-button v-if="feedList" color="secondary" :list="feedList" />
      <v-btn v-if="userMembership && userMembership.elevation !== 'none'" small color="error" class="mr-2 mb-2" :loading="leaving" @click="leave">
        Quitter l'établissement
      </v-btn>
      <v-btn small color="success" class="mr-2 mb-2">Ajouter un document</v-btn>
      <!--      <v-btn small color="success" class="mr-2 mb-2" :to="{name:'establishment_invite'}">-->
      <!--        Inviter des utilisateurs-->
      <!--      </v-btn>-->
    </v-card-text>
  </v-card>
</template>

<script>
  import {Establishment, EstablishmentMembership, FeedList} from '@/models'
  import FeedSubscribeButton from '@/modules/feeds/components/FeedSubscribeButton'

  export default {
    name: 'EstablishmentActions',
    components: {FeedSubscribeButton},
    props: {
      establishment: Establishment
    },
    data() {
      return {
        leaving: false
      }
    },
    computed: {
      userMembership() {
        return this.establishment.membership ? EstablishmentMembership.get(this.establishment.membership) : null
      },
      feedList() {
        return this.establishment.feedList ? FeedList.get(this.establishment.feedList) : null
      }
    },
    methods: {
      async leave() {
        if (!this.$can('leave', this.establishment)) {
          await this.$confirm('Vous ne pouvez pas quitter un établissement dont vous êtes le seul manageur', 'info')
          return
        }

        if (!await this.$confirm('Êtes-vous sûr de vouloir quitter cet établissement ?')) {
          return
        }

        try {
          this.leaving = true
          await this.establishment.leave()
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.leaving = false
        }
      }
    }
  }
</script>

<style scoped>

</style>
