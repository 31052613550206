<template>
  <v-card ref="card" class="mx-auto elevation-x">
    <v-card-title class="d-flex justify-space-between">
      <span>Membres de l'établissement</span>
      <v-btn icon small @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-list class="pb-0" two-line>
      <q-infinite-scroll scroll-target=".is-scroll-target" @load="load">
        <user-list-item v-for="membership in memberships" :key="membership.user"
                        :user="person(membership.user)"
        />
        <template v-slot:loading>
          <skeleton-list small />
        </template>
      </q-infinite-scroll>
    </v-list>
  </v-card>
</template>

<script>
  import {QInfiniteScroll} from 'quasar'
  import {Person, Establishment, EstablishmentMembership} from '@/models'
  import UserListItem from '@/modules/core/layout/UserListItem'
  import {mapActions} from 'vuex'
  import SkeletonList from '@/modules/core/layout/SkeletonList'

  export default {
    name: 'EstablishmentMembers',
    components: {SkeletonList, UserListItem, QInfiniteScroll},
    props: {
      scrollTarget: Object,
      establishment: Establishment
    },
    data() {
      return {}
    },
    computed: {
      total() {
        return this.$store.state.establishments.memberships.total
      },
      limit() {
        return this.$store.state.establishments.memberships.limit
      },
      memberships() {
        return [].concat.apply([], Object.values(this.$store.state.establishments.memberships.items)).map(m => EstablishmentMembership.get(m))
      },
      person() {
        return p => Person.get(p)
      },
      actions() {
        return membership => {
          let actions = []
          if (membership.user !== this.$auth.user.sub && this.$can('update_managers', this.establishment)) {
            let isManager = membership.elevation === 'manager'
            actions.push({
              label: isManager ? 'Retirer des managers' : 'Ajouter aux managers',
              action: async () => {
                await membership.setManager(!isManager)
              }
            })
          }
          return actions
        }
      }
    },
    mounted() {
    },
    methods: {
      ...mapActions('establishments', ['fetchMembers']),
      async load(index, done) {
        try {
          await this.fetchMembers((this.memberships.length / this.limit) + 1)
          done(this.total <= this.memberships.length)
        } catch (e) {
          done(!await this.$confirm('Une erreur est survenue', 'retry'))
          throw e
        }
      }
    }
  }
</script>

<style scoped>

</style>
