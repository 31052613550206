<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-row>
    <v-col cols="12" md="6" xl="4" lg="5">
      <v-card class="elevation-x pa-0 pa-md-3 mb-6">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-card>
      <v-card class="elevation-x pa-0 pa-md-3 mb-6">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-card>
      <v-card class="elevation-x pa-0 pa-md-3 mb-6">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-card>
    </v-col>
    <v-col cols="12" md="6" xl="8" lg="7">
      <v-card class="elevation-x pa-0 pa-md-3 mb-6">
        <v-skeleton-loader
          v-for="i in 6"
          ref="skeleton"
          :key="i" type="list-item-avatar-three-line"
          class="mx-auto dimming-skeleton"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'SkeletonProfil'
  }
</script>




